/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Türk Osmanlı saray mimarisinin son örneği olan Yıldız Sarayı, Beşiktaş semtinin Yıldız tepesinde yer alır. Kanuni Sultan Süleyman Dönemi'nden (1520-1566) itibaren padişahlar tarafından av sahası olarak kullanılan ve Hazine-i Hassa’ya kayıtlı bu araziye ilk kasrı Sultan I. Ahmed yaptırmıştır. 18'inci yüzyıl sonunda Sultan III. Selim, validesi Mihrişah Sultan için Yıldız Kasrı'nı, babası için de bir çeşme yaptırmıştır. Genellikle yaz aylarında Yıldız Köşkü'nde oturan Sultan Abdülaziz ise Büyük Mabeyn Köşkü’nü inşa ettirmiş, daha sonra dış bahçeye Malta ve Çadır Köşklerini, asıl kısmına da Çit Kasrı’nı eklemiştir."), "\n", React.createElement(_components.p, null, "Sultan Abdulaziz döneminde elçilerin ağırlanmasında ve yüksek düzeydeki yöneticilerin toplantı salonu olarak düzenlenmiştir. tek katlı kâgir bina 60 metre uzunluğunda ve 10 metre genişliğinde olan yapıya Köşkün ön kapısındaki camekanlı girişten içeriye geçilmektedir. Bu girişin sağında bir odaya ve bu odadan kasrın salonuna, solunda ise iç içe beş odaya girilmektedir."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
